<template>
<nav class="navbar navbar-expand-lg shadow-lg py-2 bg-gray-900 flex items-center w-full justify-between">
    <div class=" flex  w-4/5 m-auto ">
      <div class="flex w-2/3"  >
      
            <img class="h-20 invisible lg:visible md:w-0 sm:w-0 lg:w-auto" src="/img/logo-icaycc-o-white.png" />
                <img class="h-20 visible lg:invisible lg:w-0  md:w-auto sm:w-auto" src="/img/logo-icaycc-o-white-min.png" />
                <img class="h-20 invisible lg:visible md:w-0 sm:w-0 lg:w-auto" src="/img/cdmx_sectei_test1.png" />
                <img class="h-20 visible lg:invisible lg:w-0  md:w-auto sm:w-auto" src="/img/cdmx_sectei_test._min.png" />
                <img class="h-20 invisible lg:visible md:w-0 sm:w-0 lg:w-auto" src="/img/logo_igef_blanco.png" />
            <img class="h-20 visible lg:invisible lg:w-0" src="/img/logo_igef_blanco_min.png" />
        </div>

        <div class="  flex px-6   relative m-auto">
            <button class="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentX" aria-controls="navbarSupportedContentX" aria-expanded="false" aria-label="Toggle navigation">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                </svg>
            </button>
            <div class="navbar-collapse collapse grow items-center w-full" id="navbarSupportedContentX">
                <ul class="navbar-nav mr-auto flex flex-row">
                    <li class="nav-item">

                        <router-link class="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-yellow-400 focus:text-yellow-700 transition duration-150 ease-in-out" to="/">Inicio</router-link>
                    </li>
                    <li class="nav-item dropdown static">
                        <a class="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-yellow-400 focus:text-yellow-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap" href="#" data-mdb-ripple="true" data-mdb-ripple-color="light" type="button" id="dropdownMenuButtonX" data-bs-toggle="dropdown" aria-expanded="false">Pronósticos
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
                            </svg>
                        </a>
                        <div class="dropdown-menu w-full mt-0 hidden shadow-lg bg-white absolute left-0 top-full" aria-labelledby="dropdownMenuButtonX">
                            <div class="px-6 lg:px-8 py-5">
                                <div class="flex flex-col">
                                    <div class="bg-white text-gray-600">

                                        <router-link class="block px-6 py-2 border-b border-gray-200  hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out" to="/wrf-chem">Contaminantes</router-link>

                                        <router-link class="block px-6 py-2 border-b border-gray-200  hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out" to="/fall3d">Dispersión de cenizas</router-link>
                                        <router-link class="block px-6 py-2 border-b border-gray-200  hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out" to="/came">Came</router-link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown static">
                        <a class="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-yellow-400 focus:text-yellow-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap" href="#" data-mdb-ripple="true" data-mdb-ripple-color="light" type="button" id="dropdownMenuButtonX" data-bs-toggle="dropdown" aria-expanded="false">Radar
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
                            </svg>
                        </a>
                        <div class="dropdown-menu  w-full mt-0 hidden shadow-lg bg-white  left-0 top-full" aria-labelledby="dropdownMenuButtonX">
                            <div class="px-6 lg:px-8 py-5">
                                <div class="flex flex-col">
                                    <div class="bg-white text-gray-600">

                                        <router-link class="block px-6 py-2 border-b border-gray-200  hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out" to="/radar/desc">Descripción</router-link>

                                        <router-link class="block px-6 py-2 border-b border-gray-200  hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out" to="/radar/productos">Productos</router-link>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-yellow-400 focus:text-yellow-700 transition duration-150 ease-in-out" to="/participantes">Créditos</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</nav>
</template>

<script>
export default {
    name: 'Footer_template'
}
</script>
