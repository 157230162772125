import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import './styles/app.css'
import 'tw-elements'
import prodViewer from './components/Prod_viewer.vue'








const fqapp = createApp(App)
fqapp.component('prod_viewer', prodViewer)
fqapp.use(VueAxios, axios)


fqapp.use(router).mount('#app')
