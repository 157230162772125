<template>
<footer class=" bg-gray-800 pt-8 pb-6">
    <div class="container mx-auto px-4">

        <div class="flex flex-wrap text-left lg:text-left">
            <div class="w-full lg:w-7/12 px-4">
                <p class=" text-gray-50 font-semibold "> Instituciones</p>
                <hr class=" mt-1 mb-5" />
                <div class=" w-full flex ">

                    <a href="https://www.sectei.cdmx.gob.mx">
                        <img class="h-20"  src="/img/cdmx_sectei_test.png" />
                    </a>
                    <a href="https://www.unam.mx/">
                        <img  class="h-20"  src="/img/escudo_unam_blanco.png" />
                    </a>
                    <a href="https://www.geofisica.unam.mx/">
                        <img class="h-20" src="/img/logo_igef_blanco.png" />
                    </a>
                    <a href="https://www.enesjuriquilla.unam.mx/">
                        <img  class="h-20" src="/img/enes_juriquilla.png" />
                    </a>
                </div>
            </div>

            <div class="w-full lg:w-5/12 px-4">
                <div class="flex flex-wrap items-top mb-6">
                    <div class="w-full lg:w-4/12 px-4 ml-auto">
                        <span class="block uppercase text-gray-50 text-sm font-semibold mb-2">Sitios Relacionados</span>
                        <hr class=" mt-1 mb-5" />
                        <ul class="list-unstyled text-gray-300 ">
                            <li>
                                <a class="text-gray-200 hover:text-yellow-300 font-semibold block pb-2 text-sm" href="https://www.creative-tim.com/presentation?ref=njs-profile">La UNAM en Línea</a>
                            </li>
                            <li>
                                <a class="hover:text-yellow-300 font-semibold block pb-2 text-sm" href="https://blog.creative-tim.com?ref=njs-profile">ICAyCC</a>
                            </li>
                                    <li>
                                <a class="hover:text-yellow-300 font-semibold block pb-2 text-sm" href="https://www.creative-tim.com/bootstrap-themes/free?ref=njs-profile">Geofísica</a>
                            </li>
                        </ul>
                    </div>
                    <div class="w-full lg:w-4/12 px-4">
                        <span class="block uppercase text-gray-50  text-sm font-semibold mb-2">Agradecimientos</span>
                        <hr class=" mt-1 mb-5" />
                        <ul class="list-unstyled text-gray-300 ">
                            <li>
                                <p> A la Secretaría de Educación, Ciencia, Tecnología e Innovación del Gobierno de la Ciudad de México </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-6 border-blueGray-300">
        <div class="flex flex-wrap items-center md:justify-between justify-center">
            <div class="w-full md:w-11/12 px-4 mx-auto text-c   enter">
                <div class="text-sm text-blueGray-500 font-semibold py-1">
                    <p class="text-xs text-white leading-none">
                        © Hecho en México, Universidad Nacional Autónoma de México (UNAM), todos los derechos reservados 2022. Esta página y sus contenidos pueden ser reproducidos con fines no lucrativos, siempre y cuando no se mutile, se cite la fuente completa y su dirección electrónica. De otra forma, requiere permiso previo por escrito de la institución. Sitio web administrado por el Instituto de Ciencias de la Atmósfera y Cambio Climático </p>

                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    name: 'Footer_template'
}
</script>

<style>
.dropdown:hover .dropdown-menu {
    display: block;
}
</style>
