<template>
<div class="my-10 w-4/5 m-auto">
    <h1 class="font-medium m-auto w-fit leading-tight text-5xl mt-0 mb-2 text-slate-700">Laboratorio de modelación de calidad del aire </h1>

    <div class="">
        <div id="carouselExampleCaptions" class="carousel slide carousel-fade relative" data-bs-ride="carousel">
            <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          
            </div>
            <div class="carousel-inner relative w-full overflow-hidden">
                <div class="carousel-item active relative float-left w-full">
                    <video class="w-full" autoplay loop muted>
                        <source src="radar/Radar_popo.mp4" type="video/mp4" />
                    </video>
                    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style="background-color: rgba(50, 123, 233, 0.1)">
                        <div class="carousel-caption hidden md:block absolute text-center">
                            <h3 class="text-2xl">Radar Popocatépetl</h3>
                        </div>
                    </div>
                </div>

                <div class="carousel-item relative float-left w-full">
                    <video class="w-full" autoplay loop muted>
                        <source src="https://mdbcdn.b-cdn.net/img/video/Nature-Sunset.mp4" type="video/mp4" />
                    </video>
                    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style="background-color: rgba(50, 233, 90, 0.1)">
                        <div class="carousel-caption hidden md:block absolute text-center">
                            <h3 class="text-2xl">Calidad de aire</h3>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="text-center bg-stone-300  text-blue-900 py-5 px-0 mt-5">
        <h2 class=" font-bold  leading-tight  text-3xl mt-0 mb-2 "> Modelación y monitoreo de calidad de aire</h2>
    </div>
    <div class="grid mt-10 md:grid-cols-3 gap-6">
        <div class="flex justify-center">
            <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a href="/wrf-chem" data-mdb-ripple="true" data-mdb-ripple-color="light">
                <img class="w-full border-2 border-t-slate-800 " src="img/wrf-chem.jpg" />            </a>
                <div class="p-6 m-auto">
                    <h5 class="text-gray-900 text-xl font-medium text-justify mb-2">WRF-CHEM</h5>
                    <p class="text-gray-700 text-base mb-4">
                        Modelo comunitario empleado para generara el pronostico de calidad del aire en el centro de México, usando información meteorológica del GFS a 0.25 Grados y el inventario de emisiones de 2016. Presenta productos de concentraciones en superficie de contaminantes criterio.
                    </p>
                    <button type="button" class="inline-block px-6 py-2.5 bg-slate-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                        <a href="/wrf-chem">Leer mas </a>
                    </button>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a href="/fall3d" data-mdb-ripple="true" data-mdb-ripple-color="light">
                   
                       <img class="w-full border-2 border-t-slate-800 " src="img/fall3D.png" />

                </a>
                <div class="p-6">
                    <h5 class="text-gray-900 text-xl font-medium mb-2">FALL3D</h5>
                    <p class="text-gray-700 text-base text-justify mb-4">
                      Pronostico de dispersión de ceniza volcánica a partir de erupciones hipotéticas de 3, 5 y 10 km con duración de 1 hora y dispersión de 8 hrs, empleando el FALL3Dv8.1. Presenta los productos con concentraciones y zonas de deposito de ceniza.
                    </p>
                    <button type="button" class="inline-block px-6 py-2.5 bg-slate-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                       <a href="/fall3d">Leer mas </a>
                    </button>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a href="/radar/desc" data-mdb-ripple="true" data-mdb-ripple-color="light">
                    <video src="radar/HSQ.mp4" type="video/mp4" loop class="clip w-full"></video>
                </a>
                <div class="p-6">
                    <h5 class="text-gray-900 text-xl font-medium mb-2">Radar Popocatépetl </h5>
                    <p class="text-gray-700 text-base text-justify mb-4">
                        El radar Popocatépetl está instalado dentro del Parque Nacional Iztaccíhuatl-Popocatépetl Específicamente, se encuentra a 4000 m s.n.m. sobre el cerro Altzomoni, a 11 km de distancia del cráter del volcán Popocatépetl.
                    </p>
                    <button type="button" class="inline-block px-6 py-2.5 bg-slate-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                        <a href="/radar/desc">Leer mas</a>
                    </button>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a href="/came" data-mdb-ripple="true" data-mdb-ripple-color="light">
                    <img class="w-full border-2 border-t-slate-800 " src="img/came_Ozono.png" />

                </a>
                <div class="p-6">
                    <h5 class="text-gray-900 text-xl font-medium mb-2">Came </h5>
                    <p class="text-gray-700 text-base text-justify mb-4">
                        Imágenes de calidad de aire .
                    </p>
                    <button type="button" class="inline-block px-6 py-2.5 bg-slate-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                        <a href="/came">Leer mas</a>
                    </button>
                </div>
            </div>
        </div>

        

    </div>

</div>
</template>

<script>
export default {
    name: 'Home_page',
    components: {},
    mounted() {

        const clip = document.querySelectorAll('.clip')
        console.log(clip)
        for (let i = 0; i < clip.length; i++) {
            clip[i].addEventListener('mouseenter', function () {
                clip[i].play()
            })
            clip[i].addEventListener('mouseout', function () {
                clip[i].pause()
            })
        }

    }
}
</script>
