import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/radar/productos',
  name: 'Radar',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Radar.vue')
},
{
  path: '/radar/desc',
  name: 'Radar_home',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Radar_home.vue')
},
{
  path: '/participantes',
  name: 'Participantes',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Credits.vue')
},
{
  path: '/wrf-chem',
  name: 'Wrf_chem',
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Wrf_chem.vue')
},
{
  path: '/fall3d/:idprod?',
  name: 'Fall3d',
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Fall3d.vue')
},
{
  path: '/came/:idprod?',
  name: 'Came',
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/Came_view.vue')
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
